import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { GERAL_PREFIX_PATH } from "configs/AppConfig";

export const GeralViews = () => {
	return (
		<Suspense fallback={<Loading cover="page" />}>
			<Switch>
				<Route
					path={`${GERAL_PREFIX_PATH}/home`}
					component={lazy(() => import(`./home`))}
				/>
				<Redirect
					from={`${GERAL_PREFIX_PATH}`}
					to={`${GERAL_PREFIX_PATH}/home`}
				/>
			</Switch>
		</Suspense>
	);
};

export default GeralViews;
