import {
	AUTHENTICATED,
	SHOW_AUTH_MESSAGE,
	HIDE_AUTH_MESSAGE,
	SIGNOUT_SUCCESS,
	SIGNUP_SUCCESS,
	SHOW_LOADING,
	SIGNIN_WITH_GOOGLE_AUTHENTICATED,
	SIGNIN_WITH_FACEBOOK_AUTHENTICATED, AUTH_TOKEN_ADMIN, AUTH_TOKEN_EMPRESA, AUTH_TOKEN_TESTADOR,
} from "../constants/Auth";
import {TokenUserTypeEnum} from "../../enum/token-user-type-enum";

const initState = {
	loading: false,
	message: "",
	showMessage: false,
	redirect: "",
	redirect_testador: "/testador-app",
	token_admin: localStorage.getItem(AUTH_TOKEN_ADMIN),
	token_empresa: localStorage.getItem(AUTH_TOKEN_EMPRESA),
	token_testador: localStorage.getItem(AUTH_TOKEN_TESTADOR),
};

const auth = (state = initState, action) => {
	switch (action.type) {
		case AUTHENTICATED:
			if (action.loginType === TokenUserTypeEnum.ADM) {
				return {
					...state,
					loading: false,
					redirect: "/adm-dois",
					token_admin: action.token,
				};
			} else if (action.loginType === TokenUserTypeEnum.EMPRESA) {
				return {
					...state,
					loading: false,
					redirect: "/empresa-app",
					token_empresa: action.token,
				};
			} else if (action.loginType === TokenUserTypeEnum.TESTADOR) {
				
				let redirect = "/testador-app";
				
				if (action.isPrimeiroAcesso) {
					redirect = "/testador-app/tutorial-video";
				} else if (!action.isCadastroCompleto) {
					redirect = "/testador-app/cadastro-um";
				}
				
				return {
					...state,
					loading: false,
					redirect_testador: redirect,
					token_testador: action.token,
				};
			} else {
				return {
					...state,
					loading: false,
				};
			}
		case SHOW_AUTH_MESSAGE:
			return {
				...state,
				message: action.message,
				showMessage: true,
				loading: false,
			};
		case HIDE_AUTH_MESSAGE:
			return {
				...state,
				message: "",
				showMessage: false,
			};
		case SIGNOUT_SUCCESS: {
			if (action.singoutType === TokenUserTypeEnum.ADM) {
				return {
					...state,
					token_admin: null,
					redirect: "/",
					loading: false,
				};
			} else if (action.singoutType === TokenUserTypeEnum.EMPRESA) {
				return {
					...state,
					token_empresa: null,
					redirect: "/",
					loading: false,
				};
			} else if (action.singoutType === TokenUserTypeEnum.TESTADOR) {
				return {
					...state,
					token_testador: null,
					redirect: "/",
					loading: false,
				};
			} else {
				return {
					...state,
					redirect: "/",
					loading: false,
				};
			}
		}
		// daqui pra baixo se for utilizar, tem que verificar se o token é do tipo ADM, EMPRESA ou TESTADOR.
		case SIGNUP_SUCCESS: {
			return {
				...state,
				loading: false,
				token: action.token,
			};
		}
		case SHOW_LOADING: {
			return {
				...state,
				loading: action.loading !== null ? action.loading : true,
			};
		}
		case SIGNIN_WITH_GOOGLE_AUTHENTICATED: {
			return {
				...state,
				loading: false,
				token: action.token,
			};
		}
		case SIGNIN_WITH_FACEBOOK_AUTHENTICATED: {
			return {
				...state,
				loading: false,
				token: action.token,
			};
		}
		default:
			return state;
	}
};

export default auth;
