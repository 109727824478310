import axios from "axios";
import FileSaver from "file-saver";
import {notification} from "antd";

import history from "../history";
import {API_BASE_URL} from "configs/AppConfig";
import {AUTH_TOKEN_ADMIN, AUTH_TOKEN_EMPRESA, AUTH_TOKEN_TESTADOR} from "redux/constants/Auth";

function onFulfilledRequest(config) {
	
	const url = window.location.href;

	const isAdm = url.includes('adm-app') || url.includes('adm-auth');
	const isEmpresa = url.includes('empresa-app') || url.includes('empresa-auth');
	const isTestador = url.includes('testador-app') || url.includes('testador-auth');
	
	let jwtToken

	if (isAdm) {
		jwtToken = localStorage.getItem(AUTH_TOKEN_ADMIN);
	}

	if (isEmpresa) {
		jwtToken = localStorage.getItem(AUTH_TOKEN_EMPRESA);
	}

	if (isTestador) {
		jwtToken = localStorage.getItem(AUTH_TOKEN_TESTADOR);
	}

	if (jwtToken) {
		config.headers[TOKEN_PAYLOAD_KEY] = jwtToken;
	}

	if (!jwtToken && !config.headers[PUBLIC_REQUEST_KEY]) {
		if (isAdm) {
			history.push(ENTRY_ROUTE_ADMIN);
		} else if (isEmpresa) {
			history.push(ENTRY_ROUTE_EMPRESA);
		} else if (isTestador) {
			history.push(ENTRY_ROUTE_TESTADOR);
		} else history.push(ENTRY_ROUTE);
	}

	return config;
}

function handleRequestError(error) {
	// Do something with request error here
	notification.error({
		message: "Error",
	});
	Promise.reject(error);
}

function handleResponseError(error) {
	let notificationParam = {
		message: "",
	};

	if (!error.response) {
		notificationParam.message = "Erro inesperado";
		notification.error(notificationParam);
		return Promise.reject(error);
	}

	if (error.response.status === 401) {
		notificationParam.message = "Falha na autenticação";
		notificationParam.description = error.response.data.message;

		const url = window.location.href;

		const isAdm = url.includes('adm-app') || url.includes('adm-auth');
		const isEmpresa = url.includes('empresa-app') || url.includes('empresa-auth');
		const isTestador = url.includes('testador-app') || url.includes('testador-auth');

		if (isAdm) {
			localStorage.removeItem(AUTH_TOKEN_ADMIN);
		}

		if (isEmpresa) {
			localStorage.removeItem(AUTH_TOKEN_EMPRESA);
		}

		if (isTestador) {
			localStorage.removeItem(AUTH_TOKEN_TESTADOR);
		}
		
		notification.error(notificationParam);
		return Promise.reject(error);
	}

	if (
		error.response.status === 404 ||
		error.response.status === 403 ||
		error.response.status === 400
	) {
		if (error.response.data?.message) {
			notificationParam.message = error.response.data.message;
		} else if (error.response.data?.error) {
			notificationParam.message = error.response.data.error;
		} else {
			notificationParam.message = "Not Found";
		}
	}

	if (error.response.status === 500) {
		if (error.response.data?.message) {
			notificationParam.message = error.response.data.message.toString();
		} else if (error.response.data?.error?.message) {
			notificationParam.message =
				error.response.data.error.message.toString();
		} else if (typeof error.response.data?.error == "string") {
			notificationParam.message = error.response.data.error.toString();
		} else {
			notificationParam.message = "Erro inesperado";
		}
	}

	if (error.response.status === 508) {
		notificationParam.message = "Time Out";
	}

	notification.error(notificationParam);

	return Promise.reject(error);
}

const ENTRY_ROUTE = "#/geral/home";
const ENTRY_ROUTE_EMPRESA = "#/empresa-auth/login";
const ENTRY_ROUTE_TESTADOR = "#/testador-auth/login";
const ENTRY_ROUTE_ADMIN = "#/adm-auth/login";
const PUBLIC_REQUEST_KEY = "public-request";
const TOKEN_PAYLOAD_KEY = "authorization";

const config = {
	baseURL: API_BASE_URL,
	timeout: 300000,
};

export const apiFetchClient = axios.create(config);

apiFetchClient.interceptors.request.use(onFulfilledRequest, handleRequestError);
apiFetchClient.interceptors.response.use((response) => {
	return response.data;
}, handleResponseError);

export const apiDownloadClient = axios.create({
	...config,
	responseType: "blob",
});

apiDownloadClient.interceptors.request.use(
	onFulfilledRequest,
	handleRequestError,
);
apiDownloadClient.interceptors.response.use((response) => {
	const filename = response.headers["content-disposition"]
		.split(";")[1]
		.split("=")[1];
	FileSaver.saveAs(response.data, filename);

	return response;
}, handleResponseError);

export default apiFetchClient;
