import React from "react";
import { Switch, Route } from "react-router-dom";
import GeralViews from "views/geral-views";

export const GeralLayout = () => {
	return (
		<div className="auth-container">
			<Switch>
				<Route path="" component={GeralViews} />
			</Switch>
		</div>
	);
};

export default GeralLayout;
