import React, { lazy, Suspense, useEffect, useRef } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { ADM_APP_PREFIX_PATH } from "configs/AppConfig";
import JwtAuthService from "services/JwtAuthService";
import MenuService from "services/MenuService";

export const AdmAppViews = () => {
	let intervalId = useRef(null);

	const populateMenu = async () => {
		try {
			if (JwtAuthService.isLogged()) {
				await MenuService.populateMenuData();
			}
		} catch (e) {}

		intervalId.current = setInterval(async () => {
			if (JwtAuthService.isLogged()) {
				await MenuService.populateMenuData();
			}
		}, 30000);
	};

	useEffect(() => {
		populateMenu();

		return () => {
			clearInterval(intervalId.current);
		};
	}, []);

	return (
		<Suspense fallback={<Loading cover="content" />}>
			<Switch>
				<Route
					path={`${ADM_APP_PREFIX_PATH}/dashboard`}
					component={lazy(() => import(`./dashboard`))}
				/>
				<Route
					path={`${ADM_APP_PREFIX_PATH}/lista-usuarios`}
					component={lazy(() => import(`./lista-usuarios`))}
				/>
				<Route
					path={`${ADM_APP_PREFIX_PATH}/usuario/:id`}
					component={lazy(() => import(`./usuario`))}
				/>
				<Route
					path={`${ADM_APP_PREFIX_PATH}/usuario`}
					component={lazy(() => import(`./usuario`))}
				/>
				<Route
					path={`${ADM_APP_PREFIX_PATH}/lista-grupos-usuario`}
					component={lazy(() => import(`./lista-grupos-usuario`))}
				/>
				<Route
					path={`${ADM_APP_PREFIX_PATH}/grupo-usuario/:id`}
					component={lazy(() => import(`./grupo-usuario`))}
				/>
				<Route
					path={`${ADM_APP_PREFIX_PATH}/grupo-usuario`}
					component={lazy(() => import(`./grupo-usuario`))}
				/>
				<Route
					path={`${ADM_APP_PREFIX_PATH}/lista-empresas`}
					component={lazy(() => import(`./lista-empresas`))}
				/>
				<Route
					path={`${ADM_APP_PREFIX_PATH}/empresa/:id`}
					component={lazy(() => import(`./empresa`))}
				/>
				<Route
					path={`${ADM_APP_PREFIX_PATH}/detalhes-empresa/:id`}
					component={lazy(() => import(`./detalhes-empresa`))}
				/>
				<Route
					path={`${ADM_APP_PREFIX_PATH}/empresa`}
					component={lazy(() => import(`./empresa`))}
				/>
				<Route
					path={`${ADM_APP_PREFIX_PATH}/lista-produtos`}
					component={lazy(() => import(`./lista-produtos`))}
				/>
				<Route
					path={`${ADM_APP_PREFIX_PATH}/produto/:id`}
					component={lazy(() => import(`./produto`))}
				/>
				<Route
					path={`${ADM_APP_PREFIX_PATH}/produto`}
					component={lazy(() => import(`./produto`))}
				/>
				<Route
					path={`${ADM_APP_PREFIX_PATH}/detalhes-produto/:id`}
					component={lazy(() => import(`./detalhes-produto`))}
				/>
				<Route
					path={`${ADM_APP_PREFIX_PATH}/lista-campanhas`}
					component={lazy(() => import(`./lista-campanhas`))}
				/>
				<Route
					path={`${ADM_APP_PREFIX_PATH}/campanha/:id`}
					component={lazy(() => import(`./campanha`))}
				/>
				<Route
					path={`${ADM_APP_PREFIX_PATH}/campanha`}
					component={lazy(() => import(`./campanha`))}
				/>
				<Route
					path={`${ADM_APP_PREFIX_PATH}/lista-categorias`}
					component={lazy(() => import(`./lista-categorias`))}
				/>
				<Route
					path={`${ADM_APP_PREFIX_PATH}/categoria/:id`}
					component={lazy(() => import(`./categoria`))}
				/>
				<Route
					path={`${ADM_APP_PREFIX_PATH}/categoria`}
					component={lazy(() => import(`./categoria`))}
				/>
				<Route
					path={`${ADM_APP_PREFIX_PATH}/lista-subcategorias`}
					component={lazy(() => import(`./lista-subcategorias`))}
				/>
				<Route
					path={`${ADM_APP_PREFIX_PATH}/subcategoria/:id`}
					component={lazy(() => import(`./subcategoria`))}
				/>
				<Route
					path={`${ADM_APP_PREFIX_PATH}/subcategoria`}
					component={lazy(() => import(`./subcategoria`))}
				/>
				<Route
					path={`${ADM_APP_PREFIX_PATH}/lista-parceiros`}
					component={lazy(() => import(`./lista-parceiros`))}
				/>
				<Route
					path={`${ADM_APP_PREFIX_PATH}/parceiro/:id`}
					component={lazy(() => import(`./parceiro`))}
				/>
				<Route
					path={`${ADM_APP_PREFIX_PATH}/parceiro`}
					component={lazy(() => import(`./parceiro`))}
				/>
				<Route
					path={`${ADM_APP_PREFIX_PATH}/lista-testadores`}
					component={lazy(() => import(`./lista-testadores`))}
				/>
				<Route
					path={`${ADM_APP_PREFIX_PATH}/detalhes-testador/:id`}
					component={lazy(() => import(`./detalhes-testador`))}
				/>

				<Route
					path={`${ADM_APP_PREFIX_PATH}/detalhes-campanha/:id`}
					component={lazy(() => import(`./detalhes-campanha`))}
				/>

				<Route
					path={`${ADM_APP_PREFIX_PATH}/detalhes-parceiro/:id`}
					component={lazy(() => import(`./detalhes-parceiro`))}
				/>

				<Route
					path={`${ADM_APP_PREFIX_PATH}/lista-pesquisa-produto`}
					component={lazy(() => import(`./lista-pesquisa-produto`))}
				/>

				<Route
					path={`${ADM_APP_PREFIX_PATH}/pesquisa-subcategoria/:id`}
					component={lazy(() => import(`./pesquisa-subcategoria`))}
				/>
				<Route
					path={`${ADM_APP_PREFIX_PATH}/lista-pesquisa-subcategoria`}
					component={lazy(() =>
						import(`./lista-pesquisa-subcategoria`),
					)}
				/>

				<Route
					path={`${ADM_APP_PREFIX_PATH}/pesquisa-subcategoria`}
					component={lazy(() => import(`./pesquisa-subcategoria`))}
				/>

				<Route
					path={`${ADM_APP_PREFIX_PATH}/pesquisa-produto/:id`}
					component={lazy(() => import(`./pesquisa-produto`))}
				/>
				<Route
					path={`${ADM_APP_PREFIX_PATH}/pesquisa-produto`}
					component={lazy(() => import(`./pesquisa-produto`))}
				/>

				<Route
					path={`${ADM_APP_PREFIX_PATH}/detalhes-pesquisa-subcategoria/:id`}
					component={lazy(() =>
						import(`./detalhes-pesquisa-subcategoria`),
					)}
				/>

				<Route
					path={`${ADM_APP_PREFIX_PATH}/detalhes-pesquisa-produto/:id`}
					component={lazy(() =>
						import(`./detalhes-pesquisa-produto`),
					)}
				/>
				<Route
					path={`${ADM_APP_PREFIX_PATH}/lista-produto-nao-cadastrado`}
					component={lazy(() =>
						import(`./lista-produto-nao-cadastrado`),
					)}
				/>

				<Route
					path={`${ADM_APP_PREFIX_PATH}/lista-respostas-pesquisa`}
					component={lazy(() => import(`./lista-respostas-pesquisa`))}
				/>
				<Route
					path={`${ADM_APP_PREFIX_PATH}/respostas-pesquisa/:id`}
					component={lazy(() => import(`./respostas-pesquisa`))}
				/>
				<Route
					path={`${ADM_APP_PREFIX_PATH}/lista-beneficio`}
					component={lazy(() => import(`./lista-beneficio`))}
				/>
				<Route
					path={`${ADM_APP_PREFIX_PATH}/beneficio/:id`}
					component={lazy(() => import(`./beneficio`))}
				/>
				<Route
					path={`${ADM_APP_PREFIX_PATH}/beneficio`}
					component={lazy(() => import(`./beneficio`))}
				/>

				<Route
					path={`${ADM_APP_PREFIX_PATH}/lista-grupos-usuario-empresa`}
					component={lazy(() =>
						import(`./lista-grupos-usuario-empresa`),
					)}
				/>
				<Route
					path={`${ADM_APP_PREFIX_PATH}/lista-usuarios-empresa`}
					component={lazy(() => import(`./lista-usuarios-empresa`))}
				/>

				<Route
					path={`${ADM_APP_PREFIX_PATH}/usuario-empresa/:id`}
					component={lazy(() => import(`./usuario-empresa`))}
				/>
				<Route
					path={`${ADM_APP_PREFIX_PATH}/usuario-empresa`}
					component={lazy(() => import(`./usuario-empresa`))}
				/>

				<Route
					path={`${ADM_APP_PREFIX_PATH}/grupo-usuario-empresa/:id`}
					component={lazy(() => import(`./grupo-usuario-empresa`))}
				/>
				<Route
					path={`${ADM_APP_PREFIX_PATH}/grupo-usuario-empresa`}
					component={lazy(() => import(`./grupo-usuario-empresa`))}
				/>

				<Redirect
					from={`${ADM_APP_PREFIX_PATH}`}
					to={`${ADM_APP_PREFIX_PATH}/dashboard`}
				/>
			</Switch>
		</Suspense>
	);
};

export default React.memo(AdmAppViews);
