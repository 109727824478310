import React from "react";
import { connect } from "react-redux";
import { Layout } from "antd";
import { toggleCollapsedNav, onMobileNavToggle } from "redux/actions/Theme";
import utils from "utils";
import NavProfile from "./NavProfile";
import {
	ADM_APP_PREFIX_PATH,
	APP_NAME,
	EMPRESA_APP_PREFIX_PATH,
	PUBLIC_PREFIX_PATH,
	TESTADOR_APP_PREFIX_PATH,
} from "../../configs/AppConfig";
import { useHistory } from "react-router-dom";

const { Header } = Layout;

export const HeaderNav = (props) => {
	const {
		headerNavColor,
		currentTheme,
		isTestador,
		isAdmin,
		isEmpresa,
		showPerfil = "true",
		color = "#307FE2",
		logoBranca = true,
	} = props;

	const mode = () => {
		if (!headerNavColor) {
			return utils.getColorContrast(
				currentTheme === "dark" ? "#00000" : "#ffffff",
			);
		}
		return utils.getColorContrast(headerNavColor);
	};
	const navMode = mode();

	const history = useHistory();

	const handleLogoClick = () => {
		if (isTestador) {
			history.push(`${TESTADOR_APP_PREFIX_PATH}/home`);
		} else if (isEmpresa) {
			history.push(`${EMPRESA_APP_PREFIX_PATH}/dashboard`);
		} else {
			history.push(`${ADM_APP_PREFIX_PATH}/dashboard`);
		}
	};

	return (
		<Header
			className={`app-header ${navMode}`}
			style={{
				backgroundColor: color,
				display: "flex",
				justifyContent: "space-between",
				alignItems: "center",
			}}
		>
			<div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
				<img
					src={
						logoBranca
							? `${PUBLIC_PREFIX_PATH}/logo-white.svg`
							: `${PUBLIC_PREFIX_PATH}/img/logo-completa.svg`
					}
					alt={`${APP_NAME} logo`}
					style={{
						maxWidth: "160px",
						maxHeight: "64px",
						objectFit: "contain",
						cursor: "pointer",
					}}
					onClick={handleLogoClick}
				/>
			</div>
			{showPerfil && (
				<NavProfile
					isTestador={isTestador}
					isAdmin={isAdmin}
					isEmpresa={isEmpresa}
					style={{ flex: 1 }}
				/>
			)}
		</Header>
	);
};

const mapStateToProps = ({ theme }) => {
	const {
		navCollapsed,
		navType,
		headerNavColor,
		mobileNav,
		currentTheme,
		direction,
	} = theme;
	return {
		navCollapsed,
		navType,
		headerNavColor,
		mobileNav,
		currentTheme,
		direction,
	};
};

export default connect(mapStateToProps, {
	toggleCollapsedNav,
	onMobileNavToggle,
})(HeaderNav);
