import antdPtBr from "antd/es/locale/pt_BR";
import ptMsg from "../locales/pt_BR.json";

const PtLang = {
	antd: {
		...antdPtBr,
		Table: {
			...antdPtBr.Table,
			triggerAsc: "Organizar em ordem crescente",
			triggerDesc: "Organizar em ordem decrescente",
			cancelSort: "Cancelar organização",
			filterReset: "Limpar",
		},
	},
	locale: "pt-BR",
	messages: {
		...ptMsg,
	},
};
export default PtLang;
