export const PermissaoUsuarioEmpresaEnum = {
	VISUALIZAR: "VISUALIZAR_USUARIO",
	CRIAR: "CRIAR_USUARIO",
	EXCLUIR: "EXCLUIR_USUARIO",
};

export const PermissaoGrupoUsuarioEmpresaEnum = {
	VISUALIZAR: "VISUALIZAR_GRUPO_USUARIO",
	CRIAR: "CRIAR_GRUPO_USUARIO",
	EXCLUIR: "EXCLUIR_GRUPO_USUARIO",
};

export const PermissaoProdutoEmpresaEnum = {
	VISUALIZAR: "VISUALIZAR_PRODUTO",
};

export const PermissaoPesquisaEmpresaEnum = {
	VISUALIZAR: "VISUALIZAR_PESQUISA",
};

export const PermissaoDashboardEmpresaEnum = {
	VISUALIZAR: "VISUALIZAR_DASHBOARD",
};
