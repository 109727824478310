import React from "react";
import { Switch, Route } from "react-router-dom";
import {AdmAuthViews} from "views/administrador/auth-views";

export const AdmAuthLayout = () => {
	return (
		<div className="auth-container">
			<Switch>
				<Route path="" component={AdmAuthViews} />
			</Switch>
		</div>
	);
};

export default AdmAuthLayout;
