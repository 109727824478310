import {
	SIDE_NAV_LIGHT,
	NAV_TYPE_SIDE,
	DIR_LTR,
} from "constants/ThemeConstant";
import { env } from "./EnvironmentConfig";

export const APP_NAME = "FOODTEST";
export const API_BASE_URL = env.API_ENDPOINT_URL;

// Configurações de rotas do geral
export const GERAL_PREFIX_PATH = "/geral";

// Configurações de rotas do administrador
export const ADM_APP_PREFIX_PATH = "/adm-app";
export const ADM_AUTH_PREFIX_PATH = "/adm-auth";

// Configurações de rotas do testador
export const TESTADOR_APP_PREFIX_PATH = "/testador-app";
export const TESTADOR_AUTH_PREFIX_PATH = "/testador-auth";

// Configurações de rotas da empresa
export const EMPRESA_APP_PREFIX_PATH = "/empresa-app";
export const EMPRESA_AUTH_PREFIX_PATH = "/empresa-auth";

export const PUBLIC_PREFIX_PATH = env.PUBLIC_PREFIX_PATH;

export const THEME_CONFIG = {
	navCollapsed: false,
	sideNavTheme: SIDE_NAV_LIGHT,
	locale: "pt",
	navType: NAV_TYPE_SIDE,
	topNavColor: "#3e82f7",
	headerNavColor: "",
	mobileNav: false,
	currentTheme: "light",
	direction: DIR_LTR,
};
