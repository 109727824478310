import {
	UPDATE_USER_ADMIN,
	UPDATE_USER_EMPRESA,
	UPDATE_USER_TESTADOR,
} from "../constants/User";

const initUser = {
	//atributos do testador
	id: null,
	name_testador: null,
	role_testador: null,
	email_testador: null,
	url_avatar: null,
	pontuacao: 0,

	//atributos do admin
	name_admin: null,
	role_admin: null,

	//atributos da empresa
	name_empresa: null,
	role_empresa: null,
	id_empresa: null,
};

const user = (state = initUser, action) => {
	switch (action.type) {
		case UPDATE_USER_ADMIN:
			return {
				...state,
				name_admin: action.name,
				role_admin: action.role,
			};
		case UPDATE_USER_EMPRESA:
			return {
				...state,
				name_empresa: action.name,
				role_empresa: action.role,
				id_empresa: action.id_empresa,
			};
		case UPDATE_USER_TESTADOR:
			return {
				...state,
				id: action.id,
				name_testador: action.name,
				role_testador: action.role,
				url_avatar: action.url_avatar,
				email_testador: action.email_testador,
				pontuacao: action.pontuacao,
			};
		default:
			return state;
	}
};

export default user;
