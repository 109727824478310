import {
	UPDATE_PERMISSOES_ADMIN,
	UPDATE_PERMISSOES_EMPRESA,
	UPDATE_PERMISSOES_TESTADOR
} from "../constants/Permissoes";

const initPermissoes = {
	permissoes_admin: {},
	permissoes_empresa: {},
	permissoes_testador: {},
};

const permissoes = (state = initPermissoes, action) => {
	switch (action.type) {
		case UPDATE_PERMISSOES_ADMIN:
			return {
				permissoes_admin: action.permissoes,
			};
		case UPDATE_PERMISSOES_EMPRESA:
			return {
				permissoes_empresa: action.permissoes,
			};
		case UPDATE_PERMISSOES_TESTADOR:
			return {
				permissoes_testador: action.permissoes,
			};
		default:
			return state;
	}
};

export default permissoes;
