import React, { lazy, Suspense, useEffect, useRef } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { TESTADOR_APP_PREFIX_PATH } from "configs/AppConfig";
import JwtAuthService from "../../../services/JwtAuthService";
import MenuService from "../../../services/MenuService";

export const TestadorAppViews = () => {
	let intervalId = useRef(null);

	const populateMenu = async () => {
		try {
			if (JwtAuthService.isLogged()) {
				await MenuService.populateMenuData();
			}
		} catch (e) {}

		intervalId.current = setInterval(async () => {
			if (JwtAuthService.isLogged()) {
				await MenuService.populateMenuData();
			}
		}, 30000);
	};

	useEffect(() => {
		populateMenu();

		return () => {
			clearInterval(intervalId.current);
		};
	}, []);

	return (
		<Suspense fallback={<Loading cover="content" />}>
			<Switch>
				<Route
					path={`${TESTADOR_APP_PREFIX_PATH}/cadastro-um`}
					component={lazy(() =>
						import(`./cadastro-usuario/cadastro-um`),
					)}
				/>

				<Route
					path={`${TESTADOR_APP_PREFIX_PATH}/cadastro-dois`}
					component={lazy(() =>
						import(`./cadastro-usuario/cadastro-dois`),
					)}
				/>

				<Route
					path={`${TESTADOR_APP_PREFIX_PATH}/cadastro-tres`}
					component={lazy(() =>
						import(`./cadastro-usuario/cadastro-tres`),
					)}
				/>

				<Route
					path={`${TESTADOR_APP_PREFIX_PATH}/cadastro-quatro`}
					component={lazy(() =>
						import(`./cadastro-usuario/cadastro-quatro`),
					)}
				/>
				<Route
					path={`${TESTADOR_APP_PREFIX_PATH}/editar-um`}
					component={lazy(() => import(`./editar-usuario/editar-um`))}
				/>

				<Route
					path={`${TESTADOR_APP_PREFIX_PATH}/editar-dois`}
					component={lazy(() =>
						import(`./editar-usuario/editar-dois`),
					)}
				/>

				<Route
					path={`${TESTADOR_APP_PREFIX_PATH}/editar-tres`}
					component={lazy(() =>
						import(`./editar-usuario/editar-tres`),
					)}
				/>

				<Route
					path={`${TESTADOR_APP_PREFIX_PATH}/editar-quatro`}
					component={lazy(() =>
						import(`./editar-usuario/editar-quatro`),
					)}
				/>
				<Route
					path={`${TESTADOR_APP_PREFIX_PATH}/tutorial-video`}
					component={lazy(() => import(`./tutorial-video`))}
				/>
				<Route
					path={`${TESTADOR_APP_PREFIX_PATH}/tutorial-inicial`}
					component={lazy(() => import(`./tutorial-inicial`))}
				/>

				<Route
					path={`${TESTADOR_APP_PREFIX_PATH}/home`}
					component={lazy(() => import(`./home`))}
				/>

				<Route
					path={`${TESTADOR_APP_PREFIX_PATH}/editar-login`}
					component={lazy(() => import(`./editar-login`))}
				/>
				<Route
					path={`${TESTADOR_APP_PREFIX_PATH}/questionario/:id`}
					component={lazy(() => import(`./questionario`))}
				/>
				<Route
					path={`${TESTADOR_APP_PREFIX_PATH}/perfil`}
					component={lazy(() => import(`./perfil`))}
				/>
				<Route
					path={`${TESTADOR_APP_PREFIX_PATH}/revisao-respostas/:id`}
					component={lazy(() => import(`./revisao-respostas`))}
				/>
				<Route
					path={`${TESTADOR_APP_PREFIX_PATH}/ranking`}
					component={lazy(() => import(`./ranking`))}
				/>
				<Route
					path={`${TESTADOR_APP_PREFIX_PATH}/resgatar`}
					component={lazy(() => import(`./resgatar`))}
				/>
				<Route
					path={`${TESTADOR_APP_PREFIX_PATH}/revisao-respostas-por-produto/:id`}
					component={lazy(() =>
						import(`./revisao-respostas-por-produto`),
					)}
				/>

				<Redirect
					from={`${TESTADOR_APP_PREFIX_PATH}`}
					to={`${TESTADOR_APP_PREFIX_PATH}/home`}
				/>
			</Switch>
		</Suspense>
	);
};

export default React.memo(TestadorAppViews);
