import {all, takeEvery, put, fork, call} from "redux-saga/effects";
import {
	AUTH_TOKEN,
	SIGNIN,
	SIGNOUT,
	SIGNUP,
	SIGNIN_WITH_GOOGLE,
	SIGNIN_WITH_FACEBOOK,
	AUTHENTICATED, AUTH_TOKEN_ADMIN, AUTH_TOKEN_EMPRESA, AUTH_TOKEN_TESTADOR,
} from "../constants/Auth";
import {
	showAuthMessage,
	authenticated,
	signOutSuccess,
	signUpSuccess,
	signInWithGoogleAuthenticated,
	signInWithFacebookAuthenticated,
} from "../actions/Auth";
import FirebaseService from "services/FirebaseService";
import MenuService from "services/MenuService";
import {TokenUserTypeEnum} from "../../enum/token-user-type-enum";

export function pegarAuthTokenASerUtilizado(userType) {
	if (userType === TokenUserTypeEnum.ADM) {
		return AUTH_TOKEN_ADMIN;
	}

	if (userType === TokenUserTypeEnum.EMPRESA) {
		return AUTH_TOKEN_EMPRESA;
	}

	if (userType === TokenUserTypeEnum.TESTADOR) {
		return AUTH_TOKEN_TESTADOR;
	}

	return null
}

export function* signIn() {
	yield takeEvery(AUTHENTICATED, function* (payload) {
		try {
			let authToken = yield call(pegarAuthTokenASerUtilizado, payload.loginType);
			
			localStorage.setItem(authToken, payload.token);

			MenuService.populateMenuData()
		} catch (err) {
			yield put(showAuthMessage(err));
		}
	});
}

export function* signOut() {
	yield takeEvery(SIGNOUT, function* (action) {
		try {

			let authToken = yield call(pegarAuthTokenASerUtilizado, action.singoutType);

			localStorage.removeItem(authToken);

			yield put(signOutSuccess(
				action.singoutType
			));
		} catch (err) {
			yield put(showAuthMessage(err));
		}
	});
}

// daqui pra baixo se voce quiser utilizar vai ter que adaptar para utilizar com a diferença de token de tipos de usuários ( adm, empresa, testador)

export function* signInWithFBEmail() {
	yield takeEvery(SIGNIN, function* ({payload}) {
		const {email, password} = payload;
		try {
			const user = yield call(
				FirebaseService.signInEmailRequest,
				email,
				password,
			);
			if (user.message) {
				yield put(showAuthMessage(user.message));
			} else {
				localStorage.setItem(AUTH_TOKEN, user.user.uid);
				yield put(authenticated(user.user.uid));
			}
		} catch (err) {
			yield put(showAuthMessage(err));
		}
	});
}

export function* signUpWithFBEmail() {
	yield takeEvery(SIGNUP, function* ({payload}) {
		const {email, password} = payload;
		try {
			const user = yield call(
				FirebaseService.signUpEmailRequest,
				email,
				password,
			);
			if (user.message) {
				yield put(showAuthMessage(user.message));
			} else {
				localStorage.setItem(AUTH_TOKEN, user.user.uid);
				yield put(signUpSuccess(user.user.uid));
			}
		} catch (error) {
			yield put(showAuthMessage(error));
		}
	});
}

export function* signInWithFBGoogle() {
	yield takeEvery(SIGNIN_WITH_GOOGLE, function* () {
		try {
			const user = yield call(FirebaseService.signInGoogleRequest);
			if (user.message) {
				yield put(showAuthMessage(user.message));
			} else {
				localStorage.setItem(AUTH_TOKEN, user.user.uid);
				yield put(signInWithGoogleAuthenticated(user.user.uid));
			}
		} catch (error) {
			yield put(showAuthMessage(error));
		}
	});
}

export function* signInWithFacebook() {
	yield takeEvery(SIGNIN_WITH_FACEBOOK, function* () {
		try {
			const user = yield call(FirebaseService.signInFacebookRequest);
			if (user.message) {
				yield put(showAuthMessage(user.message));
			} else {
				localStorage.setItem(AUTH_TOKEN, user.user.uid);
				yield put(signInWithFacebookAuthenticated(user.user.uid));
			}
		} catch (error) {
			yield put(showAuthMessage(error));
		}
	});
}

export default function* rootSaga() {
	yield all([
		fork(signIn),
		fork(signInWithFBEmail),
		fork(signOut),
		fork(signUpWithFBEmail),
		fork(signInWithFBGoogle),
		fork(signInWithFacebook),
	]);
}
