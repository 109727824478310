/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Avatar, Dropdown, Menu, message } from "antd";
import { connect } from "react-redux";
import { CaretDownOutlined } from "@ant-design/icons";
import { signOut } from "redux/actions/Auth";
import { TokenUserTypeEnum } from "../../enum/token-user-type-enum";
import {
	PUBLIC_PREFIX_PATH,
	TESTADOR_APP_PREFIX_PATH,
} from "../../configs/AppConfig";
import { useHistory } from "react-router-dom";
import store from "redux/store";
import { UPDATE_USER_TESTADOR } from "redux/constants/User";
import { AUTH_TOKEN_TESTADOR } from "../../redux/constants/Auth";

const { info } = message;

const menuItem = [
	{
		title: "Meu perfil",
		icon: "UserOutlined",
		path: "/perfil",
		pronto: true,
	},
	// {
	// 	title: "Configurações",
	// 	icon: "SettingOutlined",
	// 	path: "/configuracoes",
	// 	pronto: false,
	// },
	{
		title: "Editar cadastro",
		icon: "EditOutlined",
		path: "/editar-um",
		pronto: true,
	},
	{
		title: "Editar login",
		icon: "LockOutlined",
		path: "/editar-login",
		pronto: true,
	},
];

export const NavProfile = ({
	signOut,
	user,
	isTestador,
	isAdmin,
	isEmpresa,
}) => {
	const sair = () => {
		if (isTestador) {
			signOut(TokenUserTypeEnum.TESTADOR);
			store.dispatch({
				type: UPDATE_USER_TESTADOR,
				name: null,
				url_avatar: null,
				email_testador: null,
				pontuacao: 0,
				id: null,
				role: null,
			});
		}

		if (isAdmin) {
			signOut(TokenUserTypeEnum.ADM);
		}

		if (isEmpresa) {
			signOut(TokenUserTypeEnum.EMPRESA);
		}
	};

	let history = useHistory();

	const infor = () => {
		info("Esta página ainda esta em desenvolvimento.", 6);
	};

	let jwtToken = localStorage.getItem(AUTH_TOKEN_TESTADOR);

	const profileMenu = (
		<div>
			<div className="nav-profile-body">
				<Menu style={{ padding: 10 }}>
					{isTestador &&
						menuItem.map((el, i) => {
							return (
								<Menu.Item key={i}>
									<a
										onClick={() => {
											el.pronto
												? history.push(
														`${TESTADOR_APP_PREFIX_PATH}${el.path}`,
												  )
												: infor();
										}}
									>
										<span className="font-weight-normal">
											{el.title}
										</span>
									</a>
								</Menu.Item>
							);
						})}

					{(jwtToken || isEmpresa || isAdmin) && (
						<Menu.Item key={4} onClick={() => sair()}>
							<span>
								<span className="font-weight-normal">Sair</span>
							</span>
						</Menu.Item>
					)}
				</Menu>
			</div>
		</div>
	);

	const getInitials = (name) => {
		// Remove espaços extras e divide a string em palavras
		const words = name.trim().split(/\s+/);

		// Pega a inicial de cada palavra e junta com pontos
		return words.map((word) => word.charAt(0).toUpperCase()).join(".");
	};

	return (
		<Dropdown
			placement="bottomRight"
			overlay={profileMenu}
			trigger={["click"]}
		>
			<Menu className="d-flex align-item-center" mode="horizontal">
				<Menu.Item key="profile" style={{ color: "#fff" }}>
					{isAdmin && (
						<Avatar
							style={{
								border: "0.1px solid #307FE2",
							}}
						>
							<span style={{ color: "#307FE2" }}>
								{user.name_admin
									? getInitials(user.name_admin)
									: ""}
							</span>
						</Avatar>
					)}

					{isEmpresa && (
						<Avatar
							style={{
								border: "0.1px solid #307FE2",
							}}
						>
							<span style={{ color: "#307FE2" }}>
								{user.name_empresa
									? getInitials(user.name_empresa)
									: ""}
							</span>
						</Avatar>
					)}

					{isTestador && (
						<Avatar
							src={
								user.url_avatar ??
								`${PUBLIC_PREFIX_PATH}/not-pic-img.png`
							}
							style={{
								border: "0.1px solid #307FE2",
								padding: 2,
							}}
						/>
					)}
					<CaretDownOutlined
						style={{
							color: isTestador ? "#307FE2" : "#fff",
							marginLeft: 5,
						}}
					/>
				</Menu.Item>
			</Menu>
		</Dropdown>
	);
};

const mapStateToProps = ({ user }) => {
	return { user: { ...user } };
};

const mapDispatchToProps = { signOut };

export default connect(mapStateToProps, mapDispatchToProps)(NavProfile);
