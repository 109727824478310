import { ShoppingOutlined, DashboardOutlined } from "@ant-design/icons";
import { EMPRESA_APP_PREFIX_PATH } from "configs/AppConfig";
import {
	PermissaoGrupoUsuarioEmpresaEnum,
	PermissaoUsuarioEmpresaEnum,
} from "enum/permissao-empresa-enum";
import { UsergroupAddOutlined, UserOutlined } from "@ant-design/icons";

const dashBoardNavTree = [
	{
		key: "dashboard",
		path: `${EMPRESA_APP_PREFIX_PATH}/dashboard`,
		title: "dashboard",
		icon: DashboardOutlined,
		breadcrumb: false,
		submenu: [],
	},
	{
		key: "lista-produtos",
		path: `${EMPRESA_APP_PREFIX_PATH}/lista-produtos`,
		title: "lista-produtos",
		icon: ShoppingOutlined,
		breadcrumb: false,
		submenu: [],
		// permissao: PermissaoProdutoEnum.VISUALIZAR,
	},
	{
		key: "gerenciamento-usuario",
		title: "gerenciamento-usuario",
		breadcrumb: false,
		submenu: [
			{
				key: "lista-usuarios",
				path: `${EMPRESA_APP_PREFIX_PATH}/lista-usuarios`,
				title: "lista-usuarios",
				icon: UserOutlined,
				breadcrumb: false,
				submenu: [],
				permissao: PermissaoUsuarioEmpresaEnum.VISUALIZAR,
			},
			{
				key: "lista-grupos-usuario",
				path: `${EMPRESA_APP_PREFIX_PATH}/lista-grupos-usuario`,
				title: "lista-grupos-usuario",
				icon: UsergroupAddOutlined,
				breadcrumb: false,
				submenu: [],
				permissao: PermissaoGrupoUsuarioEmpresaEnum.VISUALIZAR,
			},
		],
	},
];

const empresaNavigationConfig = [...dashBoardNavTree];

export default empresaNavigationConfig;
