import React from "react";
import { APP_NAME } from "configs/AppConfig";

export default function FooterAdmin() {
	return (
		<footer
			className="footer"
			style={{
				backgroundColor: "#1890ff",
				textAlign: "center",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				marginLeft: -10,
				marginRight: -10,
				color: "white",
			}}
		>
			<span>
				Direitos autorais &copy; {`${new Date().getFullYear()}`}{" "}
				<span className="font-weight-semibold">{`${APP_NAME}`}</span>{" "}
				Todos os direitos reservados
			</span>
		</footer>
	);
}
