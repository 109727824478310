import React from "react";
import {
	APP_NAME,
	PUBLIC_PREFIX_PATH,
	TESTADOR_AUTH_PREFIX_PATH,
} from "configs/AppConfig";
import { Col, Row } from "antd";
import {
	MailFilled,
	EnvironmentFilled,
	FacebookFilled,
	InstagramFilled,
	WhatsAppOutlined,
	LinkedinFilled,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

export default function Footer() {
	const history = useHistory();
	const [email] = React.useState("contato@foodtest.com.br");
	const [endereco] = React.useState(
		"Av. José dos Santos Marques, 28 Parque das quaresmeiras, Campinas/SP",
	);

	const handleCopyEmail = () => {
		navigator.clipboard.writeText(email).then(() => {
			alert("E-mail copiado com sucesso!");
		});
	};

	const handleCopyEndereco = () => {
		navigator.clipboard.writeText(endereco).then(() => {
			alert("Endereço copiado com sucesso!");
		});
	};

	return (
		<footer
			style={{
				height: "auto", // Mude para 'auto' para se adaptar ao conteúdo
				padding: "20px 0", // Adiciona um pouco de padding vertical
				background:
					"linear-gradient(3.14deg, #307FE2 2.87%, rgba(48, 127, 226, 0.67) 77.2%)",
			}}
		>
			<Row
				justify="space-around"
				gutter={[16, 48]}
				style={{ width: "100%" }}
			>
				<Col xs={24} sm={24} md={6} lg={6} xl={5}>
					<div
						style={{
							display: "flex", // Habilita o flexbox
							justifyContent: "center", // Centraliza horizontalmente
							alignItems: "center", // Centraliza verticalmente
							height: "100%", // Faz o div ocupar a altura total da coluna
							padding: "0 30px", // Padding horizontal
						}}
					>
						<img
							src={`${PUBLIC_PREFIX_PATH}/logo-white.svg`}
							alt={`${APP_NAME} logo`}
							style={{
								maxWidth: "100%",
								maxHeight: "100px",
								objectFit: "contain",
							}} // Limita o tamanho da imagem
						/>
					</div>
				</Col>
				<Col xs={24} sm={12} md={6} lg={6} xl={5}>
					<div style={{ padding: "0 30px" }}>
						<h2 style={{ color: "white", marginBottom: 10 }}>
							Navegação
						</h2>
						<h5
							style={{
								color: "white",
								fontWeight: 200,
								cursor: "pointer",
							}}
							onClick={() =>
								history.push(
									`${TESTADOR_AUTH_PREFIX_PATH}/cadastro-usuario`,
								)
							}
						>
							Cadastre-se
						</h5>
						<h5
							style={{
								color: "white",
								fontWeight: 200,
								cursor: "pointer",
							}}
							onClick={() =>
								window.open(
									"https://foodtest.com.br/",
									"_blank",
								)
							}
						>
							Como participar
						</h5>
						<h5
							style={{
								color: "white",
								fontWeight: 200,
								cursor: "pointer",
							}}
							onClick={() =>
								window.open(
									"https://foodtest.com.br/#perguntas",
									"_blank",
								)
							}
						>
							Perguntas frequentes
						</h5>
						<h5
							style={{
								color: "white",
								fontWeight: 200,
								cursor: "pointer",
							}}
							onClick={() =>
								window.open(
									"https://foodtest.com.br/politicas-de-privacidade/",
									"_blank",
								)
							}
						>
							Políticas de privacidade
						</h5>
					</div>
				</Col>
				<Col xs={24} sm={12} md={6} lg={6} xl={5}>
					<div style={{ padding: "0 30px" }}>
						{" "}
						{/* Ajuste de padding */}
						<h2 style={{ color: "white", marginBottom: 10 }}>
							Contato
						</h2>
						<h5
							style={{
								color: "white",
								fontWeight: 200,
								cursor: "pointer",
							}}
							onClick={handleCopyEmail}
						>
							<MailFilled
								color={"#fff"}
								style={{ marginRight: 8 }}
								onClick={handleCopyEmail}
							/>{" "}
							{email}
						</h5>
						<h5
							style={{
								color: "white",
								fontWeight: 200,
								cursor: "pointer",
							}}
							onClick={handleCopyEndereco}
						>
							<EnvironmentFilled
								color={"#fff"}
								style={{ marginRight: 8 }}
							/>{" "}
							{endereco}
						</h5>
					</div>
				</Col>
				<Col xs={24} sm={24} md={6} lg={6} xl={5}>
					<div
						style={{
							padding: "0 30px",
							display: "flex", // Habilita o flexbox
							justifyContent: "center", // Centraliza horizontalmente
							alignItems: "center",
							flexDirection: "column", // Centraliza verticalmente
						}}
					>
						{" "}
						{/* Ajuste de padding */}
						<h2 style={{ color: "white", marginBottom: 10 }}>
							Acompanhe
						</h2>
						<h5 style={{ color: "white", fontWeight: 200 }}>
							<FacebookFilled
								onClick={() => {
									window.open(
										"https://www.facebook.com/foodtestBR",
										"_blank",
									);
								}}
								color={"#fff"}
								style={{ fontSize: 30, marginRight: 8 }}
							/>
							<InstagramFilled
								onClick={() => {
									window.open(
										"https://www.instagram.com/projetofoodtest/",
										"_blank",
									);
								}}
								color={"#fff"}
								style={{ fontSize: 30, marginRight: 8 }}
							/>
							<WhatsAppOutlined
								onClick={() => {
									window.open(
										"https://api.whatsapp.com/send?phone=",
										"_blank",
									);
								}}
								color={"#fff"}
								style={{ fontSize: 30, marginRight: 8 }}
							/>
							<LinkedinFilled
								onClick={() => {
									window.open(
										"https://www.linkedin.com/company/foodtestbr/",
										"_blank",
									);
								}}
								color={"#fff"}
								style={{ fontSize: 30, marginRight: 8 }}
							/>
						</h5>
					</div>
				</Col>
			</Row>
		</footer>
	);
}
