import React from "react";
import { Switch, Route } from "react-router-dom";
import TestadorAuthViews from "../../../views/testador/auth-views";

export const TestadorAuthLayout = () => {
	return (
		<div className="auth-container">
			<Switch>
				<Route path="" component={TestadorAuthViews} />
			</Switch>
		</div>
	);
};

export default TestadorAuthLayout;
