import React from "react";
import { connect } from "react-redux";
import Loading from "components/shared-components/Loading";
import HeaderNav from "components/layout-components/HeaderNav";
import PageHeader from "components/layout-components/PageHeader";
import Footer from "components/layout-components/Footer";
import { Layout, Grid } from "antd";
import { NAV_TYPE_TOP } from "constants/ThemeConstant";
import utils from "utils";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { TestadorAppViews } from "../../../views/testador/app-views";

const { Content } = Layout;
const { useBreakpoint } = Grid;

export const TestadorAppLayout = ({ navType, direction, location }) => {
	let semHeadereFooter;

	if (location.pathname.includes("tutorial-inicial")) {
		semHeadereFooter = true;
	} else if (location.pathname.includes("tutorial-video")) {
		semHeadereFooter = true;
	} else if (location.pathname.includes("home")) {
		semHeadereFooter = true;
	} else if (location.pathname.includes("questionario")) {
		semHeadereFooter = true;
	} else if (location.pathname.includes("perfil")) {
		semHeadereFooter = true;
	} else if (location.pathname.includes("revisao-respostas")) {
		semHeadereFooter = true;
	} else if (location.pathname.includes("ranking")) {
		semHeadereFooter = true;
	} else if (location.pathname.includes("resgatar")) {
		semHeadereFooter = true;
	}

	const screens = utils.getBreakPoint(useBreakpoint());
	const isMobile = !screens.includes("lg");
	const isNavTop = navType === NAV_TYPE_TOP;

	const { status } = useThemeSwitcher();

	if (status === "loading") {
		return <Loading cover="page" />;
	}

	const styles =
		location.pathname.includes("cadastro") ||
		location.pathname.includes("editar")
			? { backgroundColor: "#EEEEEE" }
			: {};

	return (
		<Layout style={styles}>
			{!semHeadereFooter && (
				<HeaderNav isMobile={isMobile} isTestador={true} />
			)}
			<div
				className={
					!semHeadereFooter
						? `app-content ${isNavTop ? "layout-top-nav" : ""}`
						: ""
				}
			>
				<PageHeader display={false} title="dashboard" />
				<Content>
					<TestadorAppViews />
				</Content>
			</div>
			{!semHeadereFooter && <Footer />}
		</Layout>
	);
};

const mapStateToProps = ({ theme }) => {
	const { navCollapsed, navType, locale } = theme;
	return { navCollapsed, navType, locale };
};

export default connect(mapStateToProps)(React.memo(TestadorAppLayout));
