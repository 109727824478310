import React from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { AdmAppLayout } from "layouts/administrador/app-layout";
import { AdmAuthLayout } from "layouts/administrador/auth-layout";
import AppLocale from "lang";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from "antd";
import {
	ADM_APP_PREFIX_PATH,
	ADM_AUTH_PREFIX_PATH,
	EMPRESA_APP_PREFIX_PATH,
	EMPRESA_AUTH_PREFIX_PATH,
	GERAL_PREFIX_PATH,
	TESTADOR_APP_PREFIX_PATH,
	TESTADOR_AUTH_PREFIX_PATH,
} from "configs/AppConfig";
import useBodyClass from "hooks/useBodyClass";
import GeralLayout from "../layouts/geral-layout";
import TestadorAuthLayout from "../layouts/testador/auth-layout";
import { TestadorAppLayout } from "../layouts/testador/app-layout";
import EmpresaAuthLayout from "../layouts/empresa/auth-layout";
import { EmpresaAppLayout } from "../layouts/empresa/app-layout";

function RouteInterceptor({ children, isAuthenticated, ...rest }) {
	let isAdm = rest?.path?.includes("adm");
	let isEmpresa = rest?.path?.includes("empresa");
	let isTestador = rest?.path?.includes("testador");

	if (isAdm) {
		return (
			<Route
				{...rest}
				render={({ location }) =>
					isAuthenticated ? (
						children
					) : (
						<Redirect
							to={{
								pathname: ADM_AUTH_PREFIX_PATH,
								state: { from: location },
							}}
						/>
					)
				}
			/>
		);
	}

	if (isTestador) {
		let isHome = rest?.location?.pathname.includes("home");
		return (
			<Route
				{...rest}
				render={({ location }) =>
					isHome || isAuthenticated ? (
						children
					) : (
						<Redirect
							to={{
								pathname: TESTADOR_AUTH_PREFIX_PATH,
								state: { from: location },
							}}
						/>
					)
				}
			/>
		);
	}

	if (isEmpresa) {
		return (
			<Route
				{...rest}
				render={({ location }) =>
					isAuthenticated ? (
						children
					) : (
						<Redirect
							to={{
								pathname: EMPRESA_AUTH_PREFIX_PATH,
								state: { from: location },
							}}
						/>
					)
				}
			/>
		);
	}

	return (
		<Route
			{...rest}
			render={({ location }) => (
				<Redirect
					to={{
						pathname: GERAL_PREFIX_PATH,
						state: { from: location },
					}}
				/>
			)}
		/>
	);
}

export const Views = (props) => {
	const {
		locale,
		location,
		direction,
		token_testador,
		token_empresa,
		token_admin,
	} = props;
	const currentAppLocale = AppLocale[locale];
	useBodyClass(`dir-${direction}`);
	return (
		<IntlProvider
			locale={currentAppLocale.locale}
			messages={currentAppLocale.messages}
		>
			<ConfigProvider
				locale={currentAppLocale.antd}
				direction={direction}
			>
				<Switch>
					{/* Rota padrão para o sistema */}

					<Route exact path="/">
						<Redirect to={`${TESTADOR_APP_PREFIX_PATH}/home`} />
					</Route>

					{/* Rotas que não precisam de autenticação */}

					<Route path={GERAL_PREFIX_PATH}>
						<GeralLayout direction={direction} />
					</Route>
					<Route path={ADM_AUTH_PREFIX_PATH}>
						<AdmAuthLayout direction={direction} />
					</Route>
					<Route path={TESTADOR_AUTH_PREFIX_PATH}>
						<TestadorAuthLayout direction={direction} />
					</Route>
					<Route path={EMPRESA_AUTH_PREFIX_PATH}>
						<EmpresaAuthLayout direction={direction} />
					</Route>

					{/* Rotas que precisam de autenticação */}

					<RouteInterceptor
						path={ADM_APP_PREFIX_PATH}
						isAuthenticated={token_admin}
					>
						<AdmAppLayout
							direction={direction}
							location={location}
						/>
					</RouteInterceptor>
					<RouteInterceptor
						path={EMPRESA_APP_PREFIX_PATH}
						isAuthenticated={token_empresa}
					>
						<EmpresaAppLayout
							direction={direction}
							location={location}
						/>
					</RouteInterceptor>

					<RouteInterceptor
						path={TESTADOR_APP_PREFIX_PATH}
						isAuthenticated={token_testador}
					>
						<TestadorAppLayout
							direction={direction}
							location={location}
						/>
					</RouteInterceptor>
				</Switch>
			</ConfigProvider>
		</IntlProvider>
	);
};

const mapStateToProps = ({ theme, auth }) => {
	const { locale, direction } = theme;
	const { token_testador, token_empresa, token_admin } = auth;
	return { locale, direction, token_testador, token_empresa, token_admin };
};

export default withRouter(connect(mapStateToProps)(Views));
