import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import { Menu, Grid, Badge } from "antd";
import IntlMessage from "../util-components/IntlMessage";
import Icon from "../util-components/Icon";
import { connect } from "react-redux";
import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from "constants/ThemeConstant";
import utils from "utils";
import { onMobileNavToggle } from "redux/actions/Theme";

const { SubMenu } = Menu;
const { useBreakpoint } = Grid;

const setLocale = (isLocaleOn, localeKey) =>
	isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

const setDefaultOpen = (key) => {
	let keyList = [];
	let keyString = "";
	if (key) {
		const arr = key.split("-");
		for (let index = 0; index < arr.length; index++) {
			const elm = arr[index];
			index === 0
				? (keyString = elm)
				: (keyString = `${keyString}-${elm}`);
			keyList.push(keyString);
		}
	}
	return keyList;
};

const SideNavContent = (props) => {
	const {
		sideNavTheme,
		routeInfo,
		hideGroupTitle,
		localization,
		onMobileNavToggle,
		notificacoesMenu,
		listaPermissoes,
		navigationConfig,
	} = props;
	const isMobile = !utils.getBreakPoint(useBreakpoint()).includes("lg");
	const closeMobileNav = () => {
		if (isMobile) {
			onMobileNavToggle(false);
		}
	};

	/*
    Verifica se esse submenu tem pelo menos um filho com permissão.
    Se não tiver, retorna false, para que o submenu em si não apareça sem filhos
  */
	const verificarPermissoesSubmenu = (listaItens) => {
		for (const item of listaItens) {
			let temPermissao = verificarPermissoes(item.permissao);

			if (item.submenu && item.submenu?.length > 0) {
				temPermissao =
					temPermissao && verificarPermissoesSubmenu(item.submenu);
			}

			if (temPermissao) {
				return true;
			}
		}

		return false;
	};

	/*
    Verificar se tem a permissão ou lista de permissões passadas
    por parâmetro
  */
	const verificarPermissoes = useCallback(
		(permissoes) => {
			if (permissoes === undefined || permissoes === null) return true;

			if (!Array.isArray(permissoes)) permissoes = [permissoes];

			// Fica true caso tenha todas permissões
			for (const p of permissoes) {

				const url = window.location.href;

				const isAdm = url.includes('adm-app') || url.includes('adm-auth');
				const isEmpresa = url.includes('empresa-app') || url.includes('empresa-auth');
				const isTestador = url.includes('testador-app') || url.includes('testador-auth');
				
				let objPermissoes = {};
				
				if (isAdm) {
					objPermissoes = listaPermissoes.permissoes_admin;
				}
				if (isEmpresa) {
					objPermissoes = listaPermissoes.permissoes_empresa;
				}
				if (isTestador) {
					objPermissoes = listaPermissoes.permissoes_testador;
				}
				
				const temPermissao = !p || objPermissoes[p];
				
				if (temPermissao) {
					return true;
				}
			}

			return false;
		},
		[listaPermissoes],
	);

	return (
		<Menu
			theme={sideNavTheme === SIDE_NAV_LIGHT ? "light" : "dark"}
			mode="inline"
			style={{ height: "100%", borderRight: 0 }}
			defaultSelectedKeys={[routeInfo?.key]}
			defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
			className={hideGroupTitle ? "hide-group-title" : ""}
		>
			{navigationConfig.map((menu) =>
				menu.submenu.length > 0 ? (
					<Menu.ItemGroup
						key={menu.key}
						title={setLocale(localization, menu.title)}
						style={{
							display:
								verificarPermissoes(menu.permissao) &&
								verificarPermissoesSubmenu(menu.submenu)
									? "block"
									: "none",
						}}
					>
						{menu.submenu.map((subMenuFirst) =>
							subMenuFirst.submenu.length > 0 ? (
								<SubMenu
									icon={
										subMenuFirst.icon ? (
											<Icon type={subMenuFirst?.icon} />
										) : null
									}
									key={subMenuFirst.key}
									title={
										<Badge
											count={
												notificacoesMenu[
													subMenuFirst
														.indexNotificacoes
												]
											}
											offset={[18]}
										>
											{setLocale(
												localization,
												subMenuFirst.title,
											)}
										</Badge>
									}
									style={{
										display:
											verificarPermissoes(
												subMenuFirst.permissao,
											) &&
											verificarPermissoesSubmenu(
												subMenuFirst.submenu,
											)
												? "block"
												: "none",
									}}
								>
									{subMenuFirst.submenu.map(
										(subMenuSecond) => (
											<Menu.Item
												key={subMenuSecond.key}
												style={{
													display:
														verificarPermissoes(
															subMenuSecond.permissao,
														)
															? "block"
															: "none",
												}}
											>
												{subMenuSecond.icon ? (
													<Icon
														type={
															subMenuSecond?.icon
														}
													/>
												) : null}
												<Badge
													count={
														notificacoesMenu[
															subMenuSecond
																.indexNotificacoes
														]
													}
													offset={[18]}
												>
													<span>
														{setLocale(
															localization,
															subMenuSecond.title,
														)}
													</span>
												</Badge>
												<Link
													onClick={() =>
														closeMobileNav()
													}
													to={subMenuSecond.path}
												/>
											</Menu.Item>
										),
									)}
								</SubMenu>
							) : (
								<Menu.Item
									key={subMenuFirst.key}
									style={{
										display: verificarPermissoes(
											subMenuFirst.permissao,
										)
											? "block"
											: "none",
									}}
								>
									{subMenuFirst.icon ? (
										<Icon type={subMenuFirst.icon} />
									) : null}
									<span>
										{setLocale(
											localization,
											subMenuFirst.title,
										)}
									</span>
									<Link
										onClick={() => closeMobileNav()}
										to={subMenuFirst.path}
									/>
								</Menu.Item>
							),
						)}
					</Menu.ItemGroup>
				) : (
					<Menu.Item
						key={menu.key}
						style={{
							display: verificarPermissoes(menu.permissao)
								? "block"
								: "none",
						}}
					>
						{menu.icon ? <Icon type={menu?.icon} /> : null}
						<span>
							<Badge
								style={{ marginTop: 5 }}
								count={notificacoesMenu[menu.indexNotificacoes]}
								offset={[18]}
							>
								{setLocale(localization, menu?.title)}
							</Badge>
						</span>
						{menu.path ? (
							<Link
								onClick={() => closeMobileNav()}
								to={menu.path}
							/>
						) : null}
					</Menu.Item>
				),
			)}
		</Menu>
	);
};

const TopNavContent = (props) => {
	const { topNavColor, localization, navigationConfig } = props;
	return (
		<Menu mode="horizontal" style={{ backgroundColor: topNavColor }}>
			{navigationConfig.map((menu) =>
				menu.submenu.length > 0 ? (
					<SubMenu
						key={menu.key}
						popupClassName="top-nav-menu"
						title={
							<span>
								{menu.icon ? <Icon type={menu?.icon} /> : null}
								<span>
									{setLocale(localization, menu.title)}
								</span>
							</span>
						}
					>
						{menu.submenu.map((subMenuFirst) =>
							subMenuFirst.submenu.length > 0 ? (
								<SubMenu
									key={subMenuFirst.key}
									icon={
										subMenuFirst.icon ? (
											<Icon type={subMenuFirst?.icon} />
										) : null
									}
									title={setLocale(
										localization,
										subMenuFirst.title,
									)}
								>
									{subMenuFirst.submenu.map(
										(subMenuSecond) => (
											<Menu.Item key={subMenuSecond.key}>
												<span>
													{setLocale(
														localization,
														subMenuSecond.title,
													)}
												</span>
												<Link to={subMenuSecond.path} />
											</Menu.Item>
										),
									)}
								</SubMenu>
							) : (
								<Menu.Item key={subMenuFirst.key}>
									{subMenuFirst.icon ? (
										<Icon type={subMenuFirst?.icon} />
									) : null}
									<span>
										{setLocale(
											localization,
											subMenuFirst.title,
										)}
									</span>
									<Link to={subMenuFirst.path} />
								</Menu.Item>
							),
						)}
					</SubMenu>
				) : (
					<Menu.Item key={menu.key}>
						{menu.icon ? <Icon type={menu?.icon} /> : null}
						<span>{setLocale(localization, menu?.title)}</span>
						{menu.path ? <Link to={menu.path} /> : null}
					</Menu.Item>
				),
			)}
		</Menu>
	);
};

const MenuContent = (props) => {
	return props.type === NAV_TYPE_SIDE ? (
		<SideNavContent {...props} />
	) : (
		<TopNavContent {...props} />
	);
};

const mapStateToProps = ({ theme, menu, permissoes }) => {
	const { sideNavTheme, topNavColor } = theme;
	return {
		sideNavTheme,
		topNavColor,
		notificacoesMenu: menu,
		listaPermissoes: permissoes,
	};
};

export default connect(mapStateToProps, { onMobileNavToggle })(MenuContent);
