export const PermissaoUsuarioAdmEnum = {
	VISUALIZAR: "VISUALIZAR_USUARIO",
	CRIAR: "CRIAR_USUARIO",
	EXCLUIR: "EXCLUIR_USUARIO",
};

export const PermissaoGrupoUsuarioAdmEnum = {
	VISUALIZAR: "VISUALIZAR_GRUPO_USUARIO",
	CRIAR: "CRIAR_GRUPO_USUARIO",
	EXCLUIR: "EXCLUIR_GRUPO_USUARIO",
};

export const PermissaoCampanhaAdmEnum = {
	VISUALIZAR: "VISUALIZAR_CAMPANHA",
	CRIAR: "CRIAR_CAMPANHA",
	EXCLUIR: "EXCLUIR_CAMPANHA",
};

export const PermissaoCategoriaAdmEnum = {
	VISUALIZAR: "VISUALIZAR_CATEGORIA",
	CRIAR: "CRIAR_CATEGORIA",
	EXCLUIR: "EXCLUIR_CATEGORIA",
};

export const PermissaoSubcategoriaAdmEnum = {
	VISUALIZAR: "VISUALIZAR_SUBCATEGORIA",
	CRIAR: "CRIAR_SUBCATEGORIA",
	EXCLUIR: "EXCLUIR_SUBCATEGORIA",
};

export const PermissaoProdutoAdmEnum = {
	VISUALIZAR: "VISUALIZAR_PRODUTO",
	CRIAR: "CRIAR_PRODUTO",
	EXCLUIR: "EXCLUIR_PRODUTO",
};

export const PermissaoParceiroAdmEnum = {
	VISUALIZAR: "VISUALIZAR_PARCEIRO",
	CRIAR: "CRIAR_PARCEIRO",
	EXCLUIR: "EXCLUIR_PARCEIRO",
};

export const PermissaoPesquisaProdutoAdmEnum = {
	VISUALIZAR: "VISUALIZAR_PESQUISA_PRODUTO",
	CRIAR: "CRIAR_PESQUISA_PRODUTO",
	EXCLUIR: "EXCLUIR_PESQUISA_PRODUTO",
};

export const PermissaoPesquisaSubcategoriaAdmEnum = {
	VISUALIZAR: "VISUALIZAR_PESQUISA_SUBCATEGORIA",
	CRIAR: "CRIAR_PESQUISA_SUBCATEGORIA",
	EXCLUIR: "EXCLUIR_PESQUISA_SUBCATEGORIA",
};

export const PermissaoRespostaPesquisaAdmEnum = {
	VISUALIZAR: "VISUALIZAR_RESPOSTA_PESQUISA",
	CRIAR: "CRIAR_RESPOSTA_PESQUISA",
	EXCLUIR: "EXCLUIR_RESPOSTA_PESQUISA",
};

export const PermissaoProdutoNaoCadastradoAdmEnum = {
	VISUALIZAR: "VISUALIZAR_PRODUTO_NAO_CADASTRADO",
	CRIAR: "CRIAR_PRODUTO_NAO_CADASTRADO",
	EXCLUIR: "EXCLUIR_PRODUTO_NAO_CADASTRADO",
};

export const PermissaoUsuarioTestadorAdmEnum = {
	VISUALIZAR: "VISUALIZAR_USUARIO_TESTADOR",
	CRIAR: "CRIAR_USUARIO_TESTADOR",
	EXCLUIR: "EXCLUIR_USUARIO_TESTADOR",
};

export const PermissaoUsuarioEmpresaAdmEnum = {
	VISUALIZAR: "VISUALIZAR_USUARIO_EMPRESA",
	CRIAR: "CRIAR_USUARIO_EMPRESA",
	EXCLUIR: "EXCLUIR_USUARIO_EMPRESA",
};

export const PermissaoGrupoUsuarioEmpresaAdmEnum = {
	VISUALIZAR: "VISUALIZAR_GRUPO_USUARIO_EMPRESA",
	CRIAR: "CRIAR_GRUPO_USUARIO_EMPRESA",
	EXCLUIR: "EXCLUIR_GRUPO_USUARIO_EMPRESA",
};

export const PermissaoEmpresaAdmEnum = {
	VISUALIZAR: "VISUALIZAR_EMPRESA",
	CRIAR: "CRIAR_EMPRESA",
	EXCLUIR: "EXCLUIR_EMPRESA",
};
